import { useStaticQuery, graphql, Link } from "gatsby"
import axios from "axios"
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import EditBoardModal from "../../components/ContentBuilder/Modal/EditBoardModal"
import config from "../../config/config"
import useOutsideClick from "../../hooks/useOutsideClick"
import { getUser, setUser } from "../../services/auth"
import { updateBoard } from "../../state/board"
import { resetBoardCategory, toggleBoardCategory } from "../../state/filter"
import { setSearchValue } from "../../state/search"
import { setSort } from "../../state/sort"
import Layout from "../Page"
import QuickViewModalData from "../../components/ContentBuilder/Modal/QuickViewModalData"
import QuickViewModalHomeDesignData from "../../components/ContentBuilder/Modal/QuickViewModalHomeDesignData"

const BoardDetailPage = () => {
    
    const GlobalConfigData = useStaticQuery(graphql`
    {
    silverStripeDataObject(className: {eq: "Undigital__Objects__GlobalConfig"}) {
        UndigitalGlobalConfig {
            hidePricingGlobally
            hideStyleSection
            hideHomeDesignSection
            hideFacadesSection    
            hideFinishesFixturesSection
            hideCabinetrySection
            hideFurnitureSection
        }
      }
    }
    `)
    const globalConfig = GlobalConfigData.silverStripeDataObject.UndigitalGlobalConfig
    const dispatch = useDispatch()
    const currentSearch = useSelector(state => state.search.current)
    const currentSort = useSelector(state => state.sort.current)
    const currentBoardCategories = useSelector(state => state.filter.boardCategories)
    const [boardCats, setBoardCats] = useState({...currentBoardCategories})

  const handleSearchChange = useCallback(e => {
    dispatch(setSearchValue(e.target.value))
  }, [dispatch])

  const sortClickHandler = useCallback(val => {
    dispatch(setSort(val))
  }, [dispatch])

  const checkboxChangeHandler = useCallback(e => {
    setBoardCats(oldBoards => {
      const currentValue = oldBoards[e.target.value]
      return {
        ...oldBoards,
        [e.target.value]: !currentValue
      }
    })
    dispatch(toggleBoardCategory(e.target.value))
  }, [dispatch])

  const search = typeof window !== "undefined" ? window.location.search : "/"
  const params = new URLSearchParams(search)
  var id = params.get("id")
  var boards = []
  var board = null
  var nextBoard = null
  var prevBoard = null
  var Member = getUser()
  var nameText = ""
  var firstName = Member.FirstName
  if (firstName) {
    nameText += firstName.charAt(0)
  }
  var lastName = Member.Surname
  if (lastName) {
    nameText += lastName.charAt(0)
  }
  var items = []
  if (typeof Member.Boards !== "undefined" && Member.Boards !== null) {
    boards = Object.keys(Member.Boards).map(key => Member.Boards[key])
    board = boards.find(item => item.ID == id)
    if (typeof board === "undefined") {
      if (typeof window !== "undefined") {
        window.location.href = "/account/my-boards/"
      }
    }
    const currentBoardIndex = boards.indexOf(board)    
    var prevBoardIndex = (currentBoardIndex - 1) % boards.length;
    var nextBoardIndex = (currentBoardIndex + 1) % boards.length;
    if(prevBoardIndex < 0){
        //prevBoardIndex = boards.length - 1;
    }
    if(nextBoardIndex < 0){
        //nextBoardIndex = boards.length - 1;
    }
    nextBoard = nextBoardIndex && typeof(boards[nextBoardIndex]) !== 'undefined' ? boards[nextBoardIndex] : null
    prevBoard = typeof(boards[prevBoardIndex]) !== 'undefined' ? boards[prevBoardIndex] : null
    let itemsObj = board ? JSON.parse(board.Items) : null
    if (itemsObj) {
      items = Object.keys(itemsObj).map(function(key){
            let _item = itemsObj[key]
            _item.name = _item.Title
            _item.link = _item.Link
            _item.about = _item.About
            _item.description = _item.About
            if (_item.Link.includes("finishes-fixtures")){
                _item.className = "FinishesFixtures"                
            }
            if (_item.Link.includes("cabinetry")){
                _item.className = "Cabinetry"
            }
            if (_item.Link.includes("home-designs")){
                _item.className = "HomeDesign"
            }
            if (_item.Link.includes("facade")){
                _item.className = "Facade"
            }
            if (_item.Link.includes("furniture")){
                _item.className = "Furniture"
            }
            if (_item.Link.includes("style")){
                _item.className = "Selection"
            }
            return _item
      })
    }
  }

  if (currentSearch.length) {
    items = items.filter(it => it.Title.toLowerCase().includes(currentSearch.toLowerCase()))
  }

  if (Object.keys(currentBoardCategories).length && Object.values(currentBoardCategories).some(c => c === true)) {
    items = items.filter(it => {
      let objectClass = ''
      if (it.Link.includes("finishes-fixtures")) objectClass = "FinishesFixtures"
      if (it.Link.includes("cabinetry")) objectClass = "Cabinetry"
      if (it.Link.includes("home-designs")) objectClass = "HomeDesign"
      if (it.Link.includes("facade")) objectClass = "Facade"
      if (it.Link.includes("furniture")) objectClass = "Furniture"
      if (it.Link.includes("style")) objectClass = "Selection"
      return currentBoardCategories[objectClass]
    })
  }

  if (currentSort.length) {
    items.sort((a, b) => {
      if (currentSort === "az") {
        return a.Title > b.Title ? 1 : -1
      } else if (currentSort === "latest") {
        return Number(a.ID) < Number(b.ID) ? 1 : -1
      } else {
        return Number(a.ID) < Number(b.ID) ? 1 : -1
      }
    })
  }

  if (!id || board === null) {
    if (typeof window !== "undefined") {
      window.location.href = "/account/"
    }
  }

  const remove = item => {
    let objectClass = null

    if (item.Link.includes("finishes-fixtures"))
      objectClass = "FinishesFixtures"
    if (item.Link.includes("cabinetry")) objectClass = "Cabinetry"
    if (item.Link.includes("home-designs")) objectClass = "HomeDesign"
    if (item.Link.includes("facade")) objectClass = "Facade"
    if (item.Link.includes("furniture")) objectClass = "Furniture"
    if (item.Link.includes("style")) objectClass = "Selection"

    var _data = {
      enu: btoa(Member.Email),
      objectClass: objectClass,
      objectID: item.ID,
      boardID: +id,
    }
    if (item.VariantID) _data.variantID = item.VariantID

    axios
      .post(config.host + "/tribecaapi/removefromboard", _data)
      .then(response => {
        let _response_data = response.data
        let _response_boards = Object.keys(_response_data).map(
          key => _response_data[key]
        )
        let _board = _response_boards.find(x => x.ID == id)
        if (_board) {
          Member.Boards = _response_data
          dispatch(
            updateBoard(
              Object.keys(Member.Boards).map(key => Member.Boards[key])
            )
          )
          setUser(Member)
          window.location.reload()
        }
      })
      .catch(function (error) {})
  }

  const [showEditBoard, setShowEditBoard] = useState(false)
  const [currentBoard, setCurrentBoard] = useState(false)
  const setShowBoard = board => {
    setCurrentBoard(board)
    setShowEditBoard(true)
  }

  const [show, setShow] = useState(false)
  const ref = useRef()

  useOutsideClick(ref, () => {
    setShow(false)
  })

  const toggleSort = () => {
    setShow(prevValues => !prevValues)
  }
  
  const [showQuickView, setShowQuickView] = useState(false)
  const [showQuickViewHomeDesign, setShowQuickViewHomeDesign] = useState(false)
  const [quickViewData, setQuickViewData] = useState(null)
  const setUpQuickViewModal = useCallback((e, item, vt) => {
    e.preventDefault()
    item.vt = vt
    setQuickViewData(item)
    if(item.className === 'HomeDesign'){
        setShowQuickViewHomeDesign(true)
    }else{
        setShowQuickView(true)
    }
  }, [])
  
  const handleEnter = (e) => {
    if(e.key === 'Enter'){
        e.preventDefault()        
    }
    handleSearchChange(e)
  }
  return (
    <Layout>
      <div className="container board-detail-container">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <a href="/explore/">Explore</a>
            </li>
            <li className="breadcrumb-item">
              <a href="/account/my-boards/">My Boards</a>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              {board && board.Name}
            </li>
          </ol>
        </nav>

        <div className="row no-gutters justify-content-center">
          <div className="col-12 col-lg-10 col-xl-8">
            <div className="account-avt-wrap">
              <div className="account-avt">
                <a href="/account/my-boards/" className="account-avt-inner">
                  <span>{nameText}</span>
                </a>
              </div>
              <p className="account-avt-name d-none d-lg-block">
                {Member.FirstName}
              </p>
            </div>
          </div>
        </div>

        <div className="row no-gutters justify-content-center">
          <div className="col-12 col-lg-6 col-xl-5">
            <div className="my-account-header-wrap">
              <a className="my-account-header" href="/account/">
                <div className="my-account-header-title">My account</div>
              </a>
              <a className="my-account-header active" href="/account/my-boards/">
                <div className="my-account-header-title">My boards</div>
              </a>
            </div>
          </div>
        </div>

        <div className="board-page-title">
          <div className="row align-items-center">
            <div className="col-md">
              <h1 className="product-name-title text-uppercase">
                My Boards
              </h1>
            </div>
          </div>
        </div>

        <div className="board-nav">
            {prevBoard && (
            <a className="board-nav-prev" href={`/account/board?id=${prevBoard.ID}`}><span>Previous Board</span></a>
            )}
            {board && (
              <h2 className="product-name-title h3">
                {board.Name}
                <a
                  className="editboard ml-2"
                  show="false"
                  href="#"
                  onClick={() => setShowBoard(board)}
                >
                  <img
                    width="25"
                    src="/img/account/edit.svg"
                    alt="edit board"
                  />
                </a>
              </h2>
            )}
            {nextBoard && (
            <a className="board-nav-next" href={`/account/board?id=${nextBoard.ID}`} ><span>Next Board</span></a>
            )}
        </div>

        {/* hardcode top filter */}
        <div className="explore-functional row mt-15">
          <div className="col-lg-6 order-lg-3">
            <form className="search-box">
              <input
                onChange={handleSearchChange}
                onKeyPress={e => handleEnter(e)}
                name="keyword"
                className="filter-text"
                placeholder="SEARCH"
                defaultValue=""
              />
              <div className="filter-icon">
                <img src="/img/explore/search.svg" />
              </div>
            </form>
          </div>
          <div className="col col-lg-3 order-lg-2">
            <div
              className="explore-functional-button sort-button sort-filter-relative"
              ref={ref}
              onClick={toggleSort}
            >
              <div className="filter-text">SORT</div>
              <div className={`filter-icon${show ? " rotate-180deg" : ""}`}>
                <img src="/img/explore/sort.svg" />
              </div>
              <div className={`sort-filter-container${show ? " show" : ""}`}>
                <div className="sort-filter-item" onClick={() => sortClickHandler('popular')}>
                  <div data-sort="popular">MOST POPULAR</div>
                </div>
                <div className="sort-filter-item" onClick={() => sortClickHandler('latest')}>
                  <div data-sort="latest">NEW ARRIVALS</div>
                </div>
                <div className="sort-filter-item" onClick={() => sortClickHandler('az')}>
                  <div>A-Z</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-3 order-lg-1 d-none d-lg-block">
            <div className="sub-title">
              <span>products</span>
            </div>
          </div>
        </div>
        {/* end hardcode top filter */}

        <div className="wrap-section row py-4 py-lg-5">
          <div className="col-12 col-lg-3">
            <div className="sub-title sub-title-filter d-none d-lg-flex">
              <span>filter</span>
              <button className="filter-reset" onClick={() => dispatch(resetBoardCategory())}>
                <span>RESET</span>
                <img src="/img/explore/refresh.svg" />
              </button>
            </div>
            <div className="my-lg-4 mt-0 mb-4">
                {globalConfig.hideStyleSection == 0 && (
              <div className="custom-control custom-checkbox mb-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="style"
                  checked={boardCats['Selection']}
                  onChange={checkboxChangeHandler}
                  value="Selection"
                />
                <label className="custom-control-label" htmlFor="style">
                  STYLE
                </label>
              </div>
              )}
              {globalConfig.hideHomeDesignSection == 0 && (
              <div className="custom-control custom-checkbox mb-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="homeDesigns"
                  checked={boardCats['HomeDesign']}
                  onChange={checkboxChangeHandler}
                  value="HomeDesign"
                />
                <label className="custom-control-label" htmlFor="homeDesigns">
                  HOME DESIGNS
                </label>
              </div>
              )}
              {globalConfig.hideFacadesSection == 0 && (
              <div className="custom-control custom-checkbox mb-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="facades"
                  checked={boardCats['Facade']}
                  onChange={checkboxChangeHandler}
                  value="Facade"
                />
                <label className="custom-control-label" htmlFor="facades">
                  FACADES
                </label>
              </div>
              )}
              {globalConfig.hideFinishesFixturesSection == 0 && (
              <div className="custom-control custom-checkbox mb-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="finishesFixtures"
                  checked={boardCats['FinishesFixtures']}
                  onChange={checkboxChangeHandler}
                  value="FinishesFixtures"
                />
                <label className="custom-control-label" htmlFor="finishesFixtures">
                  FINISHES &amp; FIXTURES
                </label>
              </div>
              )}
              {globalConfig.hideCabinetrySection == 0 && (
              <div className="custom-control custom-checkbox mb-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="cabinetry"
                  checked={boardCats['Cabinetry']}
                  onChange={checkboxChangeHandler}
                  value="Cabinetry"
                />
                <label className="custom-control-label" htmlFor="cabinetry">
                  CABINETRY
                </label>
              </div>
              )}
              {globalConfig.hideFurnitureSection == 0 && (
              <div className="custom-control custom-checkbox mb-2">
                <input
                  type="checkbox"
                  className="custom-control-input"
                  id="furniture"
                  checked={boardCats['Furniture']}
                  onChange={checkboxChangeHandler}
                  value="Furniture"
                />
                <label className="custom-control-label" htmlFor="furniture">
                  FURNITURE
                </label>
              </div>
              )}
            </div>
          </div>
          <div className="col-12 col-lg-9">
            {items.length > 0 ? (
              <div className="row board-wrap">
                {items.map((item, idx) => (
                  <div
                    key={`board-item-${idx}`}
                    className="col-6 col-md-6 col-lg-4 position-relative"
                  >
                    <button
                      type="button"
                      className="btn-like board-like"
                      onClick={() => {
                        remove(item)
                      }}
                    >
                      <svg
                        className="clear-heart"
                        xmlns="http://www.w3.org/2000/svg"
                        width="35"
                        height="35"
                        viewBox="0 0 35 35"
                      >
                        <path
                          id="Path_878"
                          data-name="Path 878"
                          d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z"
                          transform="translate(21.982 6.975)"
                          fill="#fff"
                          stroke="#fff"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeMiterlimit="10"
                          strokeWidth="1.5"
                        />
                      </svg>
                      <div className="clear-item"></div>
                    </button>
                    <a
                      onClick={e => setUpQuickViewModal(e, item, item.VariantID)}
                      href={`${item.Link}${
                        item.VariantID ? `/?vid=${item.VariantID}` : ''
                      }`}
                      className={`grid-item${item.VariantID ? ' floorplan-tile' : ''}`}
                    >
                      <div className="grid-item-inner">
                        <img src={item.Image} alt={item.Title} />
                        <div className="grid-caption">
                          <h4>{item.Title}</h4>
                          {item.className !== 'Selection' && (
                            <>
                          {item.VariantName && (
                            <div className="grid-bottom">                            
                                <p className="variant-name">{item.VariantName}</p>                            
                            </div>
                          )}
                          </>
                          )}
                        </div>
                      </div>
                    </a>
                  </div>
                ))}
                <div className="col-6 col-md-6 col-lg-4 position-relative">
                  <div className="addmore-wrap">
                    <a href="/explore" className="addmore-tile">
                      <svg className="addmore-icon" width="30px" height="30px">
                        <use xlinkHref="/img/icons.svg#icon-plus"></use>
                      </svg>
                      <div className="addmore-center">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="35"
                          height="35"
                          viewBox="0 0 35 35"
                        >
                          <path
                            d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z"
                            transform="translate(21.982 6.975)"
                            fill="#000"
                            stroke="#000"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeMiterlimit="10"
                            strokeWidth="1.5"
                          />
                        </svg>
                        <h4>Add more<br/>goodies</h4>
                      </div>
                      <div className="addmore-bottom"><p>EXPLORE MORE BIRDY</p></div>
                    </a>
                  </div>
                </div>
              </div>
              ): (
                <div className="row board-wrap">
                    <div className="col-6 col-md-6 col-lg-4 position-relative">
                        <div className="addmore-wrap">
                          <a href="/explore" className="addmore-tile">
                            <svg className="addmore-icon" width="30px" height="30px">
                              <use xlinkHref="/img/icons.svg#icon-plus"></use>
                            </svg>
                            <div className="addmore-center">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="35"
                                height="35"
                                viewBox="0 0 35 35"
                              >
                                <path
                                  d="M-4.556-1.307c-5.906-10.042-18.85-3.3-16.35,8.076C-18.458,17.9-4.556,23.792-4.556,23.792S9.348,17.9,11.794,6.769C14.295-4.611,1.352-11.349-4.556-1.307Z"
                                  transform="translate(21.982 6.975)"
                                  fill="#000"
                                  stroke="#000"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                  strokeMiterlimit="10"
                                  strokeWidth="1.5"
                                />
                              </svg>
                              <h4>Add more<br/>goodies</h4>
                            </div>
                            <div className="addmore-bottom"><p>EXPLORE MORE BIRDY</p></div>
                          </a>
                        </div>
                      </div>
                </div>
            )}
          </div>
        </div>
      </div>
      <EditBoardModal
        show={showEditBoard}
        board={currentBoard}
        handleClose={() => setShowEditBoard(false)}
      />
    {quickViewData && (
        <QuickViewModalData
          show={showQuickView}
          handleClose={() => setShowQuickView(false)}
          item={quickViewData}
          hidePricingGlobally={globalConfig.hidePricingGlobally}
        />
      )}
      {quickViewData && (
      <QuickViewModalHomeDesignData
          show={showQuickViewHomeDesign}
          handleClose={() => setShowQuickViewHomeDesign(false)}
          item={quickViewData}
          hidePricingGlobally={globalConfig.hidePricingGlobally}
        />
         )}
    </Layout>   
  )
}

export default BoardDetailPage
